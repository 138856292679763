import { ActionTree, Module, MutationTree } from 'vuex';
import { RootState } from '.';
import { FieldsEditRestriction } from '../../../shared/utils/fieldEditRestriction';
import { getEditableObject } from '../helpers';
import {
  CheckChangesType,
  CommissionedUnitDetails,
  Pallet,
  Pickup,
  PickupStatus
} from '../helpers/types/index';
import ApiService from '../services/ApiService';

export type State = {
  pickups?: Array<Pickup>;
  lastRefresh: {
    date?: Date;
    count?: number;
    changedSince: boolean;
  };
  editedPickup?: Pickup;
  pickupStatuses: Array<string>;
  pickupFilteredCount: number;
  originalPickup?: Pickup;
  infoDialog: boolean;
  response: string;
};

const state: State = {
  pickups: undefined,
  pickupFilteredCount: 0,
  lastRefresh: {
    date: undefined,
    count: undefined,
    changedSince: false
  },
  editedPickup: undefined,
  pickupStatuses: [],
  originalPickup: undefined,
  infoDialog: false,
  response: ''
};

const actions: ActionTree<State, RootState> = {
  async getPickups({ commit }, { pagination, filter, statuses }) {
    const { data } = await ApiService.getPickups({ pagination, filter, statuses });

    //this is necessary because if we open details unset externalIds would be undefined
    data.result.forEach((pickup: Pickup) => {
      pickup.cargo?.forEach((cargoItem: Pallet) => {
        if (!cargoItem.externalIds) {
          cargoItem.externalIds = {};
        }
      });
    });

    const pickups: Array<Pickup> = data.result;

    commit('setPickups', pickups);
    commit('setFilteredCount', data.filteredCount);
    commit('setLastRefresh', { date: new Date(), count: data.totalCount, changedSince: false });
  },

  async checkChanges({ commit }, params) {
    const { data } = await ApiService.checkPickupChanges(CheckChangesType.pickup, {
      date: params.date,
      count: params.count
    });

    commit('setHasChanges', data.hasChanges);
  },

  editPickup({ commit }, pickup: Pickup) {
    commit('editPickup', pickup);

    state.originalPickup = pickup === undefined ? undefined : JSON.parse(JSON.stringify(pickup));
  },

  async setPickupStatuses({ commit, rootGetters, rootState }, isEditDialog: boolean = false) {
    let pickupStatuses: Array<string> = (await ApiService.getPickupStatuses()).data;
    // remove cancelled pickup status
    // - order closure module is not active (in this case the status is not used)
    // - if used in edit dialog (in this case the status cannot be changed on the dialog,
    //   there is a separate cancel button)
    if (!rootGetters['module/isOrderClosureModuleActive'] || isEditDialog) {
      pickupStatuses = pickupStatuses.filter(
        (status: string) => status !== rootState.app.enums.pickupStatus.cancelled
      );
    }
    commit('setPickupStatuses', pickupStatuses);
  },

  setPickupProperties({ commit }, properties: Partial<Pickup>) {
    commit('setPickupProperties', properties);
  },

  async savePickup({ state, rootGetters, rootState }, element?: Pickup) {
    const pickup: Pickup = element ?? JSON.parse(JSON.stringify(state.editedPickup));
    if (pickup.timeSlot?.timeSlotWidth === 1) {
      pickup.timeSlot.timeSlotWidth = undefined;
    }
    const config = rootState.app.config;
    const fieldEditRestrictions = FieldsEditRestriction.init(config);
    const editedObject: Partial<Pickup> = await getEditableObject(
      pickup,
      rootGetters['account/role'],
      fieldEditRestrictions.PICKUP_EDIT_MODULE_DEPENDENCIES,
      fieldEditRestrictions.PICKUP_FIELDS_EDIT_RESTRICTIONS
    );

    if (editedObject.timeSlot && !editedObject.timeSlot.time) {
      editedObject.timeSlot.time = undefined;
      editedObject.timeSlot.dockId = undefined;
      editedObject.timeSlot.dockName = undefined;
    }

    await ApiService.savePickup(pickup.id, pickup.__v, editedObject).catch(() => {
      /* Already showed a message to the user at this point. */
    });
  },

  setPrevStatus({ commit, rootState, state }) {
    if (state.editedPickup?.status) {
      const prevStatus = state.pickupStatuses[
        state.pickupStatuses.indexOf(state.editedPickup.status) - 1
      ]
        ? state.pickupStatuses[state.pickupStatuses.indexOf(state.editedPickup.status) - 1]
        : undefined;
      if (prevStatus) {
        if (prevStatus === rootState.app.enums.pickupStatus.new) {
          commit('setTimeSlotProperties', { time: undefined, dockId: undefined });
        }
        commit('setStatus', prevStatus);
      }
    }
  },

  setNextStatus({ commit, state }) {
    if (state.editedPickup?.status) {
      const nextStatus = state.pickupStatuses[
        state.pickupStatuses.indexOf(state.editedPickup.status) + 1
      ]
        ? state.pickupStatuses[state.pickupStatuses.indexOf(state.editedPickup.status) + 1]
        : undefined;
      if (nextStatus) {
        commit('setStatus', nextStatus);
      }
    }
  },

  async setPickupDetails({ commit }, pickupId: string) {
    const { data }: { data: Array<CommissionedUnitDetails> } = await ApiService.getPickupDetails(
      pickupId
    );
    commit('setDetails', { pickupId, details: data[0] });
  },

  async applyChanges({ commit, dispatch, state }) {
    await dispatch('savePickup');
    const savedPickup = (
      await ApiService.getPickups({
        pagination: -1,
        filter: { pickupId: state.editedPickup?.pickupId },
        statuses: undefined
      })
    ).data.result[0];
    commit('editPickup', savedPickup);
  },

  async cancelPickup({ dispatch }, pickupId: string) {
    const { status } = await ApiService.cancelPickup(pickupId);
    if (status === 200) {
      dispatch('showResponse', 'Gyári átvét sikeresen lemondva!');
    }
  },

  async uncancelPickup({ dispatch }, pickupId: string) {
    const { status } = await ApiService.uncancelPickup(pickupId);
    if (status === 200) {
      dispatch('showResponse', 'Gyári átvét sikeresen újranyitva!');
    }
  },

  showResponse({ commit }, response: string) {
    commit('showResponse', response);
  },

  closeInfoDialog({ commit }) {
    commit('closeInfoDialog');
  }
};

const mutations: MutationTree<State> = {
  setPickups(state, pickups: Array<Pickup>) {
    state.pickups = JSON.parse(JSON.stringify(pickups));
  },

  setHasChanges(state, hasChanges: boolean) {
    state.lastRefresh.changedSince = hasChanges;
  },

  setFilteredCount(state, count: number) {
    state.pickupFilteredCount = count;
  },

  setLastRefresh(
    state,
    lastRefresh: {
      date?: Date;
      count?: number;
      changedSince: boolean;
    }
  ) {
    state.lastRefresh = lastRefresh;
  },

  editPickup(state, pickup: Pickup) {
    if (pickup?.timeSlot && !pickup.timeSlot.timeSlotWidth) {
      pickup.timeSlot.timeSlotWidth = 1;
    }

    state.editedPickup = pickup;
  },

  setPickupProperties(state, properties: Partial<Pickup>) {
    state.editedPickup = {
      ...state.editedPickup,
      ...properties
    };
  },

  setTimeSlotProperties(state, timeSlotProperties: {}) {
    if (!state.editedPickup) {
      return;
    }
    state.editedPickup.timeSlot = {
      ...state.editedPickup.timeSlot,
      ...timeSlotProperties
    };
  },

  setPickupStatuses(state, pickupStatuses: Array<string>) {
    state.pickupStatuses = pickupStatuses;
  },

  setStatus(state, status: PickupStatus) {
    if (state.editedPickup) {
      state.editedPickup.status = status;
    }
  },

  setDetails(state, { pickupId, details }: { pickupId: string; details: CommissionedUnitDetails }) {
    const pickupToSet = state.pickups?.find((pickup) => pickup.pickupId === pickupId);

    if (!pickupToSet) {
      return;
    }

    pickupToSet.details = details;
  },

  showResponse(state, response: string) {
    state.response = response;
    state.infoDialog = true;
  },

  closeInfoDialog(state) {
    state.infoDialog = false;
  }
};

export const pickups: Module<State, RootState> = {
  namespaced: true,
  state,
  actions,
  mutations
};
