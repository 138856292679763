import { ActionTree, GetterTree, MutationTree } from 'vuex';
import { RootState } from '.';
import { Config } from '../helpers/types';
import { CheckProcessingResponse, Job } from '../../../shared/types/job';
import ApiService from '../services/ApiService';

export type State = {
  cancelOrdersProcessing: boolean;
  config?: Config;
  isLoading: boolean;
  ordersProcessing: CheckProcessingResponse;
  manualCombination: {
    freightIds?: string[];
    showResultCard: boolean;
    resultCardMessage?: string;
  };
  version?: { commitHash: string; buildVersion: string };
  filters: any; // TODO: find type for filters
  constants: Record<string, any>;
  enums: Record<string, any>;
  job?: Job;
};

const state: State = {
  cancelOrdersProcessing: false,
  config: undefined,
  isLoading: false,
  ordersProcessing: {
    isAutoProcessing: false,
    isLastManual: false,
    isProcessing: false,
    hasUserActiveProcessingJob: false,
    jobsInFrontOfThisOne: undefined,
    lastProcessingStatus: undefined,
    lastSmsResultCode: 0,
    newOrdersCount: 0,
    newPartialOrdersCount: 0
  },
  manualCombination: {
    freightIds: undefined,
    showResultCard: false,
    resultCardMessage: undefined
  },
  version: undefined,
  filters: {},
  constants: {},
  enums: {},
  job: undefined
};

const actions: ActionTree<State, RootState> = {
  async fetchVersion({ commit }) {
    const { data } = await ApiService.getVersion();
    commit('setVersion', data.version);
  },

  async fetchConfig({ commit, dispatch }) {
    const { data } = await ApiService.getConfig();
    const { config, constants, enums } = data;
    commit('setConfig', { config, constants, enums });
    await dispatch('basicSettings/get', null, { root: true });
    if (config.disableAuth) {
      commit('account/disableAuth', null, { root: true });
    }
  },

  async fetchOrdersProcessing({ commit, dispatch, rootState, state }) {
    const { data } = await ApiService.checkOrdersProcessing(state.job?.jobId);
    if (!data.hasUserActiveProcessingJob) {
      dispatch('unsetJob');
    }
    if (
      !data.isAutoProcessing &&
      state.ordersProcessing.isProcessing && // last state was processing
      data.lastProcessingStatus // last unseen processing status of the user
    ) {
      // current status: does-not-fit
      if (data.lastProcessingStatus === rootState.app.enums.ProcessingStatus.DOES_NOT_FIT) {
        commit('setManualCombination', {
          freightIds: undefined,
          showResultCard: true,
          message: 'Sikertelen tervezés:\n' + 'A kijelölt törtfuvarok nem férnek fel egy járműre.'
        });
      }
      // current status: noOverlappingDeliveryDates
      else if (
        data.lastProcessingStatus ===
        rootState.app.enums.ProcessingStatus.NO_OVERLAPPING_DELIVERY_DATES
      ) {
        commit('setManualCombination', {
          freightIds: undefined,
          showResultCard: true,
          message:
            'Sikertelen tervezés:\n' +
            'A kijelölt fuvarok szállítási intervallumai nem fedik egymást.'
        });
      }
      // current status: tooManyWaypoints
      else if (
        data.lastProcessingStatus === rootState.app.enums.ProcessingStatus.TOO_MANY_WAYPOINTS
      ) {
        commit('setManualCombination', {
          freightIds: undefined,
          showResultCard: true,
          message:
            'Sikertelen tervezés:\n' +
            'A fuvarokon túl sok címpont található.\n' +
            'Legfeljebb 9 egyedi címpont fűzhető fel egy fuvarra.'
        });
      }
      // current status: noRouteFound
      else if (data.lastProcessingStatus === rootState.app.enums.ProcessingStatus.ROUTE_NOT_FOUND) {
        commit('setManualCombination', {
          freightIds: undefined,
          showResultCard: true,
          message: 'Sikertelen tervezés:\n' + 'Nem sikerült útvonalat találni a címpontokhoz.'
        });
      } else if (data.lastProcessingStatus === rootState.app.enums.ProcessingStatus.NO_VEHICLE) {
        commit('setManualCombination', {
          freightIds: undefined,
          showResultCard: true,
          message:
            'Sikertelen tervezés:\n' + 'A megadott járműve(kke)l nem lehet teljesíteni a rendelést!'
        });
      } else if (
        data.lastProcessingStatus === rootState.app.enums.ProcessingStatus.ORDER_COVERAGE_ERROR
      ) {
        commit('setManualCombination', {
          freightIds: undefined,
          showResultCard: true,
          message:
            'Sikertelen tervezés:\n' +
            'A létrejövő fuvarok nem fednék tökéletesen a feldolgozott rendeléseket.'
        });
      } else if (data.lastProcessingStatus === rootState.app.enums.ProcessingStatus.DONE) {
        if (data.lastProcessingResultIds?.length > 0) {
          commit('setManualCombination', {
            freightIds: data.lastProcessingResultIds,
            showResultCard: true,
            message:
              data.lastProcessingResultIds.length > 1
                ? 'A manuális tervezés eredményeként a következő fuvarok jöttek létre: ' +
                  data.lastProcessingResultIds.join(', ')
                : 'A manuális tervezés eredményeként a következő fuvar jött létre: ' +
                  data.lastProcessingResultIds[0]
          });
        }
      }
    }
    commit('setOrdersProcessing', data);
  },

  setLoading({ commit }, isLoading) {
    commit('setLoading', isLoading);
  },

  hideManualCombineResultCard({ commit }) {
    commit('hideManualCombineResultCard');
  },

  setJob({ commit }, job: Job) {
    commit('setJob', job);
  },

  unsetJob({ commit }) {
    commit('setJob', undefined);
  },

  /**
   * Proxy api service for other modules
   */
  getApiService() {
    return ApiService;
  }
};

const mutations: MutationTree<State> = {
  setVersion(state, payload) {
    state.version = payload;
  },

  setConfig(
    state,
    {
      config,
      constants,
      enums
    }: { config: Config; constants: Record<string, any>; enums: Record<string, any> }
  ) {
    state.config = config;
    state.constants = constants;
    state.enums = enums;
  },

  setJob(state, job: Job) {
    state.job = job;
    if (job) {
      state.ordersProcessing.hasUserActiveProcessingJob = true;
      state.ordersProcessing.jobsInFrontOfThisOne = job.jobsInFrontOfThisOne;
    }
  },

  setOrdersProcessing(state, payload) {
    state.ordersProcessing = payload;
  },

  setManualCombination(
    state,
    {
      freightIds,
      showResultCard,
      message
    }: { freightIds: string[] | undefined; showResultCard: boolean; message: string }
  ) {
    state.manualCombination.freightIds = freightIds ? [...freightIds] : undefined;
    state.manualCombination.showResultCard = showResultCard;
    state.manualCombination.resultCardMessage = message;
  },

  hideManualCombineResultCard(state) {
    state.manualCombination.showResultCard = false;
  },

  setCancelOrdersProcessing(state, payload) {
    state.cancelOrdersProcessing = payload;
  },

  setLoading(state, isLoading) {
    state.isLoading = isLoading;
  },

  storeFilters(
    state,
    payload: {
      viewName: string;
      isOpen: boolean;
      values: Object;
    }
  ) {
    state.filters[payload.viewName] = payload;
  }
};

const getters: GetterTree<State, RootState> = {
  cancelOrdersProcessing: (state) => state.cancelOrdersProcessing,
  colors: (state) => state.config?.colors,
  ordersProcessing: (state) => state.ordersProcessing,
  lastProcessingFreightIds: (state) => state.manualCombination.freightIds,
  showManualCombineResultCard: (state) => state.manualCombination.showResultCard,
  manualCombineResultCardMessage: (state) => state.manualCombination.resultCardMessage,
  testOrdersJson: (state) => state.config?.testOrdersJson,
  viewConfig: (state) => state.config?.view,
  configMode: (state) => state.config?.view.mode,
  isLoading: (state) => state.isLoading,
  filters: (state) => state.filters,
  palletTypes: (state) => state.config?.palletTypes,
  logisticsPlannerType: (state) => state.config?.logisticsPlannerType,
  defaultFilters: (state) => state.config?.defaultFilters,
  fieldEditSettings: (state) => state.config?.fieldEditSettings,
  jobId: (state) => state.job?.jobId
};

export const app = {
  namespaced: true,
  state,
  actions,
  getters,
  mutations
};
