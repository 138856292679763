import { ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import { getField, updateField } from 'vuex-map-fields';

import { PalletForCalculation } from '../../../shared/types/freight';
import { add, divide, multiply, subtract } from '../../../shared/utils/calculate';
import { getGrossWeight, getPalletsCountOfFreight } from '../../../shared/utils/cargo';

import { RootState, store } from '.';
import { CommissionedUnitType, Freight, Pallet, Pickup } from '../helpers/types/index';
import ApiService from '../services/ApiService';
import { Product } from './product.module';

type transportStatus = {
  description?: string;
  text?: string;
  value?: string;
};

type PackedDownOrderItem = Pallet & {
  quantity: number;
  packedDownQuantity: number;
};

export type State = {
  commissionedUnit?: CommissionedUnitType;
  splitCargo?: Freight | Pickup;
  selectedStatus?: transportStatus;
  // eslint-disable-next-line max-len
  selectedCargoSplitType: (typeof store.state.app.enums.cargoSplitType)[keyof typeof store.state.app.enums.cargoSplitType];
  // table content, depending on selected split type
  cargoSplitListItemsByPallet: Pallet[];
  cargoSplitListItemsByQuantity: PackedDownOrderItem[];
  // remaining and packed down values
  remainingPallets: number;
  remainingQuantity: number;
  remainingWeight: number;
  packedDownPallets: number;
  packedDownQuantity: number;
  packedDownWeight: number;
  // store for affected products
  products: Product[];
};

const state: State = {
  commissionedUnit: undefined,
  splitCargo: undefined,
  selectedStatus: undefined,
  selectedCargoSplitType: undefined,
  cargoSplitListItemsByPallet: [],
  cargoSplitListItemsByQuantity: [],
  remainingPallets: 0,
  remainingQuantity: 0,
  remainingWeight: 0,
  packedDownPallets: 0,
  packedDownQuantity: 0,
  packedDownWeight: 0,
  products: []
};

const actions: ActionTree<State, RootState> = {
  editCargo({ commit, dispatch }, splitCargo) {
    commit('edit', splitCargo);
    dispatch('setCargoSplitListItems');
  },

  cancelCargoSplit({ commit }) {
    commit('resetForm');
    commit('cancel');
  },

  saveSplitCargo({ dispatch, rootState, state }) {
    if (state.selectedCargoSplitType === rootState.app.enums.cargoSplitType.byPallet) {
      state.cargoSplitListItemsByPallet.forEach((_pallet: Pallet) => {
        _pallet.transportStatus = _pallet.selected
          ? state.selectedStatus?.value
          : rootState.app.enums.allTransportStatus.unknown;
      });
    } else if (state.selectedCargoSplitType === rootState.app.enums.cargoSplitType.byQuantity) {
      state.cargoSplitListItemsByQuantity.forEach((_packedDownOrderItem: PackedDownOrderItem) => {
        _packedDownOrderItem.transportStatus =
          _packedDownOrderItem.packedDownQuantity > 0
            ? state.selectedStatus?.value
            : rootState.app.enums.allTransportStatus.unknown;
      });
    }

    let response = {};
    if ((state.splitCargo as Freight).freightId) {
      response = ApiService.splitFreightCargo(
        (state.splitCargo as Freight).freightId,
        (state.splitCargo as Freight).__v,
        state.selectedCargoSplitType === rootState.app.enums.cargoSplitType.byPallet
          ? state.cargoSplitListItemsByPallet
          : state.cargoSplitListItemsByQuantity,
        state.selectedStatus?.text,
        state.selectedCargoSplitType
      );
    } else if ((state.splitCargo as Pickup).pickupId) {
      response = ApiService.splitPickupCargo(
        (state.splitCargo as Pickup).pickupId,
        state.selectedCargoSplitType === rootState.app.enums.cargoSplitType.byPallet
          ? state.cargoSplitListItemsByPallet
          : state.cargoSplitListItemsByQuantity,
        state.selectedStatus?.text,
        state.selectedCargoSplitType
      );
    }

    dispatch('cancelCargoSplit');
    return response;
  },

  addCargoSplitPalletToSelected(
    { commit },
    { palletId, selectValue }: { palletId: number; selectValue: boolean }
  ) {
    commit('setPalletSelection', { palletId, selectValue });
  },

  addCargoSplitPackedDownUnitQuantity(
    { commit },
    { index, quantity }: { index: number; quantity: number }
  ) {
    commit('setPackedDownQuantity', { index, quantity });
  },

  setCargoSplitListItems: ({ commit, rootState, state }) => {
    if (state.splitCargo && state.splitCargo.cargo) {
      if (state.selectedCargoSplitType === rootState.app.enums.cargoSplitType.byPallet) {
        commit('setListItems', {
          listItems: JSON.parse(JSON.stringify(state.splitCargo.cargo)),
          cargoSplitTypes: rootState.app.enums.cargoSplitType
        });
      } else if (state.selectedCargoSplitType === rootState.app.enums.cargoSplitType.byQuantity) {
        const itemsByQuantity: Record<string, string | number>[] = [];
        state.splitCargo.cargo.forEach((_pallet) => {
          const pallet = JSON.parse(JSON.stringify(_pallet));

          if (
            itemsByQuantity.every(
              (item) =>
                !(
                  item.orderId === pallet.orderId &&
                  item.orderItemId === pallet.orderItemId &&
                  item.orderItemMainExternalId === pallet.orderItemMainExternalId
                )
            )
          ) {
            pallet.cost = undefined;
            pallet.palletId = undefined;
            itemsByQuantity.push(pallet);
          } else {
            const index = itemsByQuantity.findIndex(
              (item) =>
                item.orderId === pallet.orderId &&
                item.orderItemId === pallet.orderItemId &&
                item.orderItemMainExternalId === pallet.orderItemMainExternalId
            );

            itemsByQuantity[index].kg = add(Number(itemsByQuantity[index].kg), pallet.kg);
            itemsByQuantity[index].quantity = add(
              Number(itemsByQuantity[index].quantity),
              pallet.quantity
            );
          }
        });

        commit('setListItems', {
          listItems: itemsByQuantity,
          cargoSplitTypes: rootState.app.enums.cargoSplitType
        });
      }
    } else {
      commit('setListItems', {
        listItems: [],
        cargoSplitTypes: rootState.app.enums.cargoSplitType
      });
    }
  },

  /**
   * Refreshes the results of the split cargo (remaining and packed down weight, pallets, etc.)
   */
  refreshResults: ({ state, rootState, dispatch }) => {
    if (state.selectedCargoSplitType === rootState.app.enums.cargoSplitType.byPallet) {
      const remainCargo = state.cargoSplitListItemsByPallet.filter(
        (cargoItem) => !(cargoItem as Pallet).selected
      );
      const packedCargo = state.cargoSplitListItemsByPallet.filter(
        (cargoItem) => (cargoItem as Pallet).selected
      );

      dispatch('refreshPallets', { remainCargo, packedCargo });
      dispatch('refreshWeights', { remainCargo, packedCargo });
    } else if (state.selectedCargoSplitType === rootState.app.enums.cargoSplitType.byQuantity) {
      // create virtual cargo items for calculation
      const remainCargo: PalletForCalculation[] = [];
      const packedCargo: PalletForCalculation[] = [];
      state.remainingQuantity = 0;
      state.packedDownQuantity = 0;
      // iterate over all cargoSplitListItemsByQuantity and create virtual cargo items
      state.cargoSplitListItemsByQuantity.forEach((item) => {
        let remainQuantity = item.quantity - item.packedDownQuantity;
        let packedQuantity = item.packedDownQuantity;
        state.remainingQuantity += remainQuantity;
        state.packedDownQuantity += packedQuantity;
        const product = state.products.find((product) => product.itemId === item.itemId);
        if (!product) {
          return;
        }
        while (remainQuantity > 0) {
          const quantity = Math.min(remainQuantity, product.unitPerPallet!);

          remainCargo.push({
            cost: divide(quantity, product.unitPerPallet!),
            palletType: product.palletType!,
            kg: multiply(product.kgPerUnit!, quantity)
          });
          remainQuantity = subtract(remainQuantity, quantity);
        }
        while (packedQuantity > 0) {
          const quantity = Math.min(packedQuantity, product.unitPerPallet!);
          packedCargo.push({
            cost: divide(quantity, product.unitPerPallet!),
            palletType: product.palletType!,
            kg: multiply(product.kgPerUnit!, quantity)
          });
          packedQuantity = subtract(packedQuantity, quantity);
        }
      });

      // with the virtual cargo items can calculate for both freight and pickup
      dispatch('refreshWeights', { remainCargo, packedCargo });
    }
  },

  refreshWeights(
    { state, rootState },
    {
      remainCargo,
      packedCargo
    }: {
      remainCargo: PalletForCalculation[];
      packedCargo: PalletForCalculation[];
    }
  ) {
    const config = rootState.app.config;

    state.remainingWeight = getGrossWeight({
      cargo: remainCargo,
      palletTypes: config?.palletTypes ?? {},
      partialPalletRoundingLimit:
        state.commissionedUnit === CommissionedUnitType.Freight
          ? config?.partialPalletRoundingLimit ?? 0
          : 0
    });

    state.packedDownWeight = getGrossWeight({
      cargo: packedCargo,
      palletTypes: config?.palletTypes ?? {},
      partialPalletRoundingLimit:
        state.commissionedUnit === CommissionedUnitType.Freight
          ? config?.partialPalletRoundingLimit ?? 0
          : 0
    });
  },

  refreshPallets(
    { state, rootState },
    {
      remainCargo,
      packedCargo
    }: { remainCargo: PalletForCalculation[]; packedCargo: PalletForCalculation[] }
  ) {
    const config = rootState.app.config;

    state.remainingPallets = getPalletsCountOfFreight({
      cargo: remainCargo,
      palletTypes: config?.palletTypes ?? {},
      partialPalletRoundingLimit:
        state.commissionedUnit === CommissionedUnitType.Freight
          ? config?.partialPalletRoundingLimit ?? 0
          : 0,
      logisticsPlannerType: config?.logisticsPlannerType
    });

    state.packedDownPallets = getPalletsCountOfFreight({
      cargo: packedCargo,
      palletTypes: config?.palletTypes ?? {},
      partialPalletRoundingLimit:
        state.commissionedUnit === CommissionedUnitType.Freight
          ? config?.partialPalletRoundingLimit ?? 0
          : 0,
      logisticsPlannerType: config?.logisticsPlannerType
    });
  },

  async initBasics({ commit }, commissionedUnit: CommissionedUnitType) {
    commit('setCommissionedUnit', commissionedUnit);
    if (!state.splitCargo || !state.splitCargo.cargo) {
      return;
    }
    // get unique itemIds from the cargo
    const itemIds = Array.from(new Set(state.splitCargo.cargo.map((pallet) => pallet.itemId)));
    const { data } = await ApiService.getProductsByItemIds(itemIds);
    commit('setProductItems', data);
  }
};

const mutations: MutationTree<State> = {
  edit(state, payload) {
    state.splitCargo = payload;
    state.selectedCargoSplitType = store.state.app.enums.cargoSplitType.byPallet;
  },

  setListItems(
    state,
    {
      listItems,
      cargoSplitTypes
    }: { listItems: Pallet[] | PackedDownOrderItem[]; cargoSplitTypes: Record<string, string> }
  ) {
    if (state.selectedCargoSplitType === cargoSplitTypes.byPallet) {
      state.cargoSplitListItemsByPallet = listItems.map((item: any) => {
        return {
          ...item,
          selected: false,
          // Using this to prevent warnings in browsers console, because Vue/Vuex
          // doesn't really change the array at the end of the function.
          // It rather merges the existing object with the new one at the same index position.
          // The result of this behaviour is packedDownQuantity = NaN when splitting by pallet
          // and undefined cost, palletId, etc. when splitting by quantity
          // This phenomenon doesn't cause any errors but is very annoying
          packedDownQuantity: 0
        } as Pallet;
      });
    } else if (state.selectedCargoSplitType === cargoSplitTypes.byQuantity) {
      state.cargoSplitListItemsByQuantity = listItems.map((item: any) => {
        return {
          ...item,
          packedDownQuantity: 0
        } as PackedDownOrderItem;
      });
    }
  },

  setPalletSelection(state, { palletId, selectValue }: { palletId: number; selectValue: boolean }) {
    const cargoSplitListItem = (state.cargoSplitListItemsByPallet as Array<Pallet>).find(
      (cargoSplitListItem) => cargoSplitListItem.palletId === palletId
    );
    if (cargoSplitListItem) {
      cargoSplitListItem.selected = selectValue;
    }
  },

  setPackedDownQuantity(state, { index, quantity }: { index: number; quantity: number }) {
    if (quantity > state.cargoSplitListItemsByQuantity[index].quantity) {
      // if passed quantity is greater than max --> set to max
      state.cargoSplitListItemsByQuantity[index].packedDownQuantity =
        state.cargoSplitListItemsByQuantity[index].quantity;
    } else if (!quantity || quantity < 0) {
      // if passed quantity is less than 0, or not set (deleted from input field) --> set to 0
      state.cargoSplitListItemsByQuantity[index].packedDownQuantity = 0;
    } else {
      // if passed quantity is in valid range (from 0 to max order item quantity)
      state.cargoSplitListItemsByQuantity[index].packedDownQuantity = quantity;
    }
  },

  setCommissionedUnit(state, commissionedUnit) {
    state.commissionedUnit = commissionedUnit;
  },

  setProductItems(state, products) {
    state.products = products;
  },

  cancel(state) {
    state.splitCargo = undefined;
    state.cargoSplitListItemsByPallet = [];
    state.cargoSplitListItemsByQuantity = [];
  },

  resetForm(state) {
    state.selectedStatus = undefined;
  },

  updateField
};

const getters: GetterTree<State, RootState> = {
  getField
};

export const splitCargo: Module<State, RootState> = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
