import { AxiosError } from 'axios';
import { ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import { getField, updateField } from 'vuex-map-fields';

import confirm from '../helpers/confirm';
import ApiService from '../services/ApiService';
import { RootState } from './';

type FreightCost = {
  id: string;
  endDistance: number;
  freightCost: number;
  type: string;
  factoryId: string;
  overRanges: boolean;
};

type WaypointCost = {
  id: string;
  quantity: number;
  cost: number;
};

type PalletFee = {
  id: string;
  endDistance: number;
  fee: number;
};

type State = {
  factoryId?: string;
  palletFees: PalletFee[];
  freightCosts: FreightCost[];
  waypointCosts: WaypointCost[];
  editedFreightCost?: Partial<FreightCost>;
  editedWaypointCost?: Partial<WaypointCost>;
  editedPalletFee?: Partial<PalletFee>;
  isNewFreightCost: boolean;
  isNewWaypointCost: boolean;
  isNewPalletFee: boolean;
  isOverRanges: boolean;
};

const state: State = {
  factoryId: undefined,
  palletFees: [],
  freightCosts: [],
  waypointCosts: [],
  editedFreightCost: undefined,
  editedWaypointCost: undefined,
  editedPalletFee: undefined,
  isNewFreightCost: false,
  isNewWaypointCost: false,
  isNewPalletFee: false,
  isOverRanges: false
};

const actions: ActionTree<State, RootState> = {
  async refresh({ commit }) {
    try {
      commit('clear');
      const { data: freightCosts } = await ApiService.getFreightCostsByFactoryId(state.factoryId);
      commit('setFreightCosts', freightCosts);
      const { data: palletFees } = await ApiService.getPalletFees();
      commit('setPalletFees', palletFees);
      const { data: waypointCosts } = await ApiService.getWaypointCosts();
      commit('setWaypointCosts', waypointCosts);
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.code === 'ERR_CANCELED') {
          return;
        }
      }

      throw error;
    }
  },

  addNewFreightCost(
    { commit },
    { overRanges, factoryId }: { overRanges: boolean; factoryId?: string }
  ) {
    commit('setIsOverRanges', overRanges);
    commit('addNewFreightCost', { overRanges, factoryId });
  },

  async setFactoryId({ commit, dispatch }, factoryId?: string) {
    commit('setFactoryId', factoryId);
    await dispatch('refresh');
  },

  cloneFreightCostForFactory(
    { commit, dispatch },
    { id, factoryId }: { id: string; factoryId: string }
  ) {
    commit('cloneFreightCostForFactory', { id, factoryId });
    dispatch('saveFreightCost', state.editedFreightCost?.type);
    commit('setFactoryId', factoryId);
  },

  editFreightCost({ commit }, id: string) {
    commit('editFreightCost', id);
  },

  async removeFreightCost({ dispatch }, id: string) {
    await ApiService.deleteFreightCost(id);
    dispatch('refresh');
  },

  async removeFreightCostsByFactoryId({ dispatch }, factoryId: string) {
    await ApiService.deleteFreightCostsByFactoryId(factoryId);
    await dispatch('setFactoryId', undefined);
  },

  async saveFreightCost({ commit, dispatch }, type: string) {
    commit('setType', type);
    await ApiService.saveFreightCost(state.editedFreightCost);
    dispatch('refresh');
  },

  cancelFreightCost({ commit }) {
    commit('clear');
  },

  addNewWaypointCost({ commit }) {
    commit('addNewWaypointCost');
  },

  editWaypointCost({ commit }, id: string) {
    commit('editWaypointCost', id);
  },

  async removeWaypointCost({ dispatch }, id: string) {
    await ApiService.deleteWaypointCost(id);
    dispatch('refresh');
  },

  async saveWaypointCost({ dispatch }) {
    await ApiService.saveWaypointCost(state.editedWaypointCost);
    dispatch('refresh');
  },

  cancelWaypointCost({ commit }) {
    commit('clear');
  },

  addNewPalletFee({ commit }) {
    commit('addNewPalletFee');
  },

  editPalletFee({ commit }, id: string) {
    commit('editPalletFee', id);
  },

  cancelPalletFee({ commit }) {
    commit('cancelPalletFee');
  },

  async savePalletFee({ commit, dispatch }) {
    await ApiService.savePalletFee(state.editedPalletFee);
    dispatch('refresh');
    commit('savePalletFee');
  },

  async deletePalletFee({ dispatch }, id: string) {
    const answer = await confirm('Biztosan törli a kiválasztott fuvardíjat?', {
      title: 'Fuvardíj törlése',
      width: 375
    });

    if (answer) {
      await ApiService.deletePalletFee(id);
      dispatch('refresh');
    }
  }
};

const mutations: MutationTree<State> = {
  clear(state) {
    state.editedFreightCost = undefined;
    state.editedWaypointCost = undefined;
    state.isNewFreightCost = false;
    state.isNewWaypointCost = false;
  },
  cloneFreightCostForFactory(state, { id, factoryId }: { id: string; factoryId: string }) {
    state.editedFreightCost = JSON.parse(
      JSON.stringify(state.freightCosts.find((cost) => cost.id === id))
    );
    if (state.editedFreightCost) {
      delete state.editedFreightCost.id;
      state.editedFreightCost.factoryId = factoryId;
    }
  },
  setFactoryId(state, factoryId: string) {
    state.factoryId = factoryId;
  },
  setFreightCosts(state, freightCosts: FreightCost[]) {
    state.freightCosts = freightCosts;
  },
  setPalletFees(state, palletFees: PalletFee[]) {
    state.palletFees = palletFees;
  },
  setWaypointCosts(state, waypointCosts: WaypointCost[]) {
    state.waypointCosts = waypointCosts;
  },
  addNewFreightCost(state, { overRanges, factoryId }: { overRanges: boolean; factoryId?: string }) {
    state.isNewFreightCost = true;
    state.editedFreightCost = { overRanges, factoryId };
    if (overRanges) {
      state.editedFreightCost.endDistance = -1;
    }
  },
  editFreightCost(state, id: string) {
    state.isNewFreightCost = false;
    state.editedFreightCost = { ...state.freightCosts.find((cost) => cost.id === id) };
  },
  setType(state, type: string) {
    state.editedFreightCost = { ...state.editedFreightCost, type };
  },
  addNewWaypointCost(state) {
    state.isNewWaypointCost = true;
    state.editedWaypointCost = {};
  },
  editWaypointCost(state, id: string) {
    state.isNewWaypointCost = false;
    state.editedWaypointCost = { ...state.waypointCosts.find((cost) => cost.id === id) };
  },

  addNewPalletFee(state) {
    state.isNewPalletFee = true;
    state.editedPalletFee = {};
  },
  editPalletFee(state, id: string) {
    state.isNewPalletFee = false;
    state.editedPalletFee = { ...state.palletFees.find((cost) => cost.id === id) };
  },

  cancelPalletFee(state) {
    state.isNewPalletFee = false;
    state.editedPalletFee = undefined;
  },

  savePalletFee(state) {
    state.isNewPalletFee = false;
    state.editedPalletFee = undefined;
  },

  setIsOverRanges(state, isOverRanges: boolean) {
    state.isOverRanges = isOverRanges;
  },

  updateField
};

const getters: GetterTree<State, RootState> = {
  palletFees: (state) => state.palletFees,
  freightCosts: (state) => state.freightCosts,
  waypointCosts: (state) => state.waypointCosts,
  editedFreightCost: (state) => state.editedFreightCost,
  editedWaypointCost: (state) => state.editedWaypointCost,
  isNewFreightCost: (state) => state.isNewFreightCost,
  isNewWaypointCost: (state) => state.isNewWaypointCost,
  isNewPalletFee: (state) => state.isNewPalletFee,
  editedPalletFee: (state) => state.editedPalletFee,
  getField
};

export const freightFees: Module<State, RootState> = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
