import { AxiosError } from 'axios';
import { ActionTree, GetterTree, MutationTree, Module } from 'vuex';

import confirm from '../helpers/confirm';
import {
  CapacityEstimate,
  CapacityEstimateVehicle,
  LastRefresh,
  Transporter
} from '../helpers/types';
import ApiService from '../services/ApiService';
import { RootState } from '.';

export type State = {
  capacityEstimates?: CapacityEstimate[];
  editedCapacityEstimate?: CapacityEstimate;
  capacityEstimatesFilteredCount?: number;
  lastRefresh: LastRefresh;
  transporters?: Transporter[];
  isNew: boolean;
};

const state: State = {
  capacityEstimates: [],
  editedCapacityEstimate: undefined,
  capacityEstimatesFilteredCount: undefined,
  lastRefresh: {
    date: undefined,
    count: undefined,
    changedSince: false
  },
  transporters: [],
  isNew: false
};

const actions: ActionTree<State, RootState> = {
  async getCapacityEstimates({ commit, state }, options: { pagination: any; filter: any }) {
    try {
      const {
        data
      }: {
        data: {
          result: CapacityEstimate[];
          totalCount: number;
          filteredCount: number;
        };
      } = await ApiService.getCapacityEstimates(options);
      const capacityEstimates = data.result;
      capacityEstimates.forEach(
        (item) =>
          (item.transporterName = state.transporters?.find(
            (tr) => tr.id === item.transporterId
          )?.companyName)
      );
      commit('setData', capacityEstimates);
      commit('setLastRefresh', {
        date: new Date(),
        count: data.totalCount,
        changedSince: false
      });
      commit('setFilteredCount', data.filteredCount);
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.code === 'ERR_CANCELED') {
          return;
        }
      }

      throw error;
    }
  },

  editCapacityEstimate({ commit, rootGetters }, capacityEstimate?: CapacityEstimate) {
    if (capacityEstimate) {
      commit('edit', capacityEstimate);
    } else {
      commit('addNew', rootGetters['account/user'].transporter);
    }
  },

  cancelEditingCapacityEstimate({ commit }) {
    commit('clearEdited');
  },

  async saveCapacityEstimate({ state }) {
    if (state.isNew) {
      await ApiService.createCapacityEstimate(state.editedCapacityEstimate);
    } else {
      await ApiService.updateCapacityEstimate(state.editedCapacityEstimate);
    }
  },

  async deleteCapacityEstimate({ commit }, id: string) {
    const answer = await confirm('Biztosan törli a kiválasztott kapacitás előrejelzést?', {
      title: 'Törlés',
      width: 375
    });
    if (answer) {
      await ApiService.deleteCapacityEstimate(id);
      commit('delete', id);
    }
  },

  setProperties({ commit, state }, properties: Partial<CapacityEstimate>) {
    if (state.editedCapacityEstimate) {
      commit('setProperties', properties);
    }
  },

  addNewVehicle({ commit, getters }) {
    const nextTruckType: string = getters['freeVehicleIds'][0];
    commit('addNewVehicle', { truckType: nextTruckType, quantity: 1 });
  },

  removeVehicle({ commit }, id: string) {
    commit('removeVehicle', id);
  },

  setVehicleTruckType({ commit }, { oldType, newType }: { oldType: string; newType: string }) {
    commit('setVehicleTruckType', { oldType, newType });
  },

  setVehicleQuantity({ commit }, { type, quantity }: { type: string; quantity: number }) {
    commit('setVehicleQuantity', { type, quantity });
  },

  async checkChanges({ state, commit }) {
    const { data } = await ApiService.checkCapacityEstimateChanges({
      date: state.lastRefresh.date,
      count: state.lastRefresh.count
    });

    commit('setLastRefresh', { changedSince: data.hasChanges });
  },

  async getTransporters({ commit }) {
    const { data } = await ApiService.getTransporters();
    commit('setTransporters', data);
  }
};

const mutations: MutationTree<State> = {
  setData(state: State, capacityEstimates: CapacityEstimate[]) {
    state.capacityEstimates = capacityEstimates;
  },

  addNew(state: State, transporterId?: string) {
    state.editedCapacityEstimate = {
      transporterId: transporterId,
      returning: false,
      vehicles: []
    };
    state.isNew = true;
  },

  edit(state: State, capacityEstimate: CapacityEstimate) {
    state.editedCapacityEstimate = capacityEstimate;
    state.isNew = false;
  },

  delete(state: State, id: string) {
    state.capacityEstimates = state.capacityEstimates?.filter((estimate) => estimate.id !== id);
  },

  setProperties(state: State, properties: Partial<CapacityEstimate>) {
    state.editedCapacityEstimate = {
      ...state.editedCapacityEstimate!,
      ...properties
    };
  },

  addNewVehicle(state: State, vehicle: CapacityEstimateVehicle) {
    if (!state.editedCapacityEstimate?.vehicles) {
      state.editedCapacityEstimate!.vehicles = [];
    }
    if (state.editedCapacityEstimate!.vehicles.some((v) => v.truckType === vehicle.truckType)) {
      return;
    } else {
      state.editedCapacityEstimate!.vehicles.push(vehicle);
    }
  },

  removeVehicle(state: State, id: string) {
    state.editedCapacityEstimate!.vehicles = state.editedCapacityEstimate!.vehicles.filter(
      (vehicle) => vehicle.truckType !== id
    );
  },

  setVehicleTruckType(state: State, { oldType, newType }: { oldType: string; newType: string }) {
    const vehicle = state.editedCapacityEstimate!.vehicles.find((v) => v.truckType === oldType);
    if (vehicle) {
      vehicle.truckType = newType;
    }
  },

  setVehicleQuantity(state: State, { type, quantity }: { type: string; quantity: number }) {
    const vehicle = state.editedCapacityEstimate!.vehicles.find((v) => v.truckType === type);
    if (vehicle) {
      vehicle.quantity = quantity;
    }
  },

  clearEdited(state: State) {
    state.editedCapacityEstimate = undefined;
    state.isNew = false;
  },

  setFilteredCount(state, count?: number) {
    state.capacityEstimatesFilteredCount = count;
  },

  setLastRefresh(
    state,
    options: {
      date?: Date;
      count?: number;
      changedSince?: boolean;
    }
  ) {
    state.lastRefresh.date = options.date ?? state.lastRefresh.date;
    state.lastRefresh.count = options.count ?? state.lastRefresh.count;
    state.lastRefresh.changedSince = options.changedSince ?? state.lastRefresh.changedSince;
  },

  setTransporters(state, transporters?: Transporter[]) {
    state.transporters = transporters;
  }
};

const getters: GetterTree<State, RootState> = {
  freeVehicleIds: (state: State, _g: any, _rs: any, rootGetters: any) =>
    rootGetters['vehicles/vehicleIds'].filter(
      (id: string) =>
        !state.editedCapacityEstimate?.vehicles.some((vehicle) => vehicle.truckType === id)
    )
};

export const capacityEstimate: Module<State, RootState> = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
