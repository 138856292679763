import { PalletForCalculation } from '../types/freight';
import { PalletType } from '../types/palletType';
import { LogisticsPlannerType } from './enums';

import { checkPalletType, getHeaviestPalletType } from './pallet';
import { roundPartialCost } from './rounding';

/**
 * Returns the gross weight of the cargo, based on the cost value of the pallets.
 * @param cargo cargo items of a freight that have cost value
 * @param partialPalletRoundingLimit the limit of cost value for partial pallets
 * @param palletTypes pallet types object to get the ratio of the pallet type
 * @returns the gross weight of the cargo
 */
export function getGrossWeight({
  cargo,
  partialPalletRoundingLimit,
  palletTypes
}: {
  cargo: PalletForCalculation[];
  partialPalletRoundingLimit: number;
  palletTypes: Record<string, PalletType>;
}): number {
  // if there is no cargo, return 0
  if (cargo.length === 0) {
    return 0;
  }
  let grossWeight = cargo.reduce(
    (acc, pallet) =>
      acc +
      pallet.kg +
      checkPalletType({ palletType: pallet.palletType, palletTypes }).kg *
        roundPartialCost(pallet.cost, partialPalletRoundingLimit),
    0
  );
  // check if all pallets are partial pallets
  if (cargo.every((pallet) => pallet.cost < partialPalletRoundingLimit)) {
    // get unique pallet types from cargo
    const uniquePalletTypes = Array.from(new Set(cargo.map((pallet) => pallet.palletType)));
    // get the heaviest pallet type from the unique pallet types
    const heaviestPalletType = getHeaviestPalletType({
      availablePalletTypes: uniquePalletTypes,
      palletTypes
    });
    // add the heaviest pallet type weight to the gross weight
    grossWeight += heaviestPalletType.kg;
  }
  return grossWeight;
}

/**
 * Returns the count of pallets that the passed cargo is packed on,
 * based on pallet rounding limit. Minimum 1 pallet is needed for packing.
 * @param cargo cargo items of a freight that have cost value
 * @param partialPalletRoundingLimit the limit of cost value for partial pallets
 * @returns the count of pallets that this cargo is packed
 */
export function getPalletsCountOfFreight({
  cargo,
  partialPalletRoundingLimit,
  palletTypes,
  logisticsPlannerType
}: {
  cargo: { cost: number; palletType: string }[];
  partialPalletRoundingLimit: number;
  palletTypes: Record<string, PalletType>;
  logisticsPlannerType: LogisticsPlannerType;
}): number {
  // if there is no cargo, return 0
  if (cargo.length === 0) {
    return 0;
  }
  let counter = 0;
  cargo.forEach((pallet) => {
    counter += getPalletCount({
      pallet,
      partialPalletRoundingLimit,
      palletTypes,
      logisticsPlannerType
    });
  });

  return counter > 0 ? counter : 1;
}

/**
 * Get the pallet count based on the cost value of the pallet.
 * @param pallet - pallet object with cost and palletType to calculate the pallet count
 * @param partialPalletRoundingLimit - the limit of cost value for partial pallets
 * @param palletTypes - pallet types object to get the ratio of the pallet type
 * @param logisticsPlannerType - the type of logistics planner to calculate the pallet count
 * @returns
 */
export function getPalletCount({
  pallet,
  partialPalletRoundingLimit,
  palletTypes,
  logisticsPlannerType = LogisticsPlannerType.LOGISTICS
}: {
  pallet: { cost: number; palletType: string };
  partialPalletRoundingLimit: number;
  palletTypes: Record<string, PalletType>;
  logisticsPlannerType: LogisticsPlannerType;
}): number {
  if (pallet.cost >= partialPalletRoundingLimit) {
    if (logisticsPlannerType === LogisticsPlannerType.LOOM) {
      const palletType = palletTypes[pallet.palletType];
      const ratio = palletType && palletType.ratio ? palletType.ratio : 1;
      return ratio;
    }
    return 1;
  }
  return 0;
}
