import { AxiosError } from 'axios';
import moment from 'moment-mini';
import { getField, updateField } from 'vuex-map-fields';
import { ActionTree, GetterTree, MutationTree, Module } from 'vuex';

import { RootState } from '.';
import {
  CommissionedUnit,
  CommissionedUnitType,
  Depot,
  Dock,
  Freight,
  Pickup
} from '../helpers/types/index';
import { Order } from '../helpers/types/order';

import ApiService from '../services/ApiService';
import { Calendar } from './calendar.module';

type Day = {
  date: string;
  enabled: boolean;
};

type TimeSlot = {
  text?: string;
  value?: string;
};

export type State = {
  depots?: Depot[];
  freights?: Freight[];
  pickups?: Pickup[];
  weekdays: Day[];

  timeSlots?: TimeSlot[];
  docks?: Dock[];
};

const state: State = {
  depots: undefined,
  freights: undefined,
  pickups: undefined,
  weekdays: [],
  timeSlots: undefined,
  docks: undefined
};

const actions: ActionTree<State, RootState> = {
  async refreshDepots({ commit }) {
    const { data } = await ApiService.getDepots();
    commit('setDepots', data);
  },

  async refreshCommissionedUnits({ commit, rootGetters, rootState }, depotShortName: string) {
    if (state.weekdays) {
      try {
        const { data: freights }: { data: (Freight & { joinedOrderIds: string })[] } =
          await ApiService.getFreightsForOrganizer({
            deliveryDateFrom: rootState.commissionedUnitOrganizer.weekdays[0].date,
            deliveryDateTo: rootState.commissionedUnitOrganizer.weekdays[6].date,
            depotShortName: depotShortName
          });

        for (const freight of freights) {
          const uniqueOrderIds = [...new Set(freight.cargo?.map((cargoItem) => cargoItem.orderId))];
          freight.joinedOrderIds = uniqueOrderIds.join(', ');
        }
        commit('setFreights', freights);

        const pickupModuleIsActive = rootGetters['module/isPickupModuleActive'];
        if (pickupModuleIsActive) {
          const { data: pickups }: { data: Pickup[] } = await ApiService.getPickupsForOrganizer({
            deliveryDateFrom: rootState.commissionedUnitOrganizer.weekdays[0].date,
            deliveryDateTo: rootState.commissionedUnitOrganizer.weekdays[6].date,
            depotShortName: depotShortName
          });
          commit('setPickups', pickups);
        }
      } catch (error) {
        if (error instanceof AxiosError) {
          if (error.code === 'ERR_CANCELED') {
            return;
          }
        }

        throw error;
      }
    }
  },

  async refreshWeekdays({ commit }, firstDay: string) {
    const { data: overrides } = await ApiService.queryCalendar({
      from: firstDay,
      to: moment(firstDay).add(6, 'days').toISOString()
    });
    commit('setWeekdays', { firstDay, overrides });
  },

  async addDetailsToCommissionedUnit({ commit }, commissionedUnit: CommissionedUnit) {
    if (commissionedUnit.orders) {
      if (commissionedUnit.type === CommissionedUnitType.Freight) {
        commit('setFreightDetails', { ...commissionedUnit, orders: undefined });
      } else if (commissionedUnit.type === CommissionedUnitType.Pickup) {
        commit('setPickupDetails', { ...commissionedUnit, orders: undefined });
      }
    } else {
      let orders: Order[] = [];
      if (commissionedUnit.type === CommissionedUnitType.Freight) {
        const { data } = await ApiService.getFreightDetails(
          (commissionedUnit as Freight).freightId
        );

        orders = data;
      } else if (commissionedUnit.type === CommissionedUnitType.Pickup) {
        const { data } = await ApiService.getPickupDetails((commissionedUnit as Pickup).pickupId);
        orders = data;
      }

      for (const order of orders) {
        const { data } = await ApiService.getOrderByOrderId(order.orderId);
        Object.assign(order, data);
      }

      if (commissionedUnit.type === CommissionedUnitType.Freight) {
        commit('setFreightDetails', { ...commissionedUnit, orders: orders });
      } else if (commissionedUnit.type === CommissionedUnitType.Pickup) {
        commit('setPickupDetails', { ...commissionedUnit, orders: orders });
      }
    }
  },

  async editCommissionedUnit({ dispatch }, commissionedUnit: CommissionedUnit) {
    if (commissionedUnit.type === CommissionedUnitType.Freight) {
      await dispatch('freight/editFreight', { ...(commissionedUnit as Freight) }, { root: true });
    } else if (commissionedUnit.type === CommissionedUnitType.Pickup) {
      await dispatch('pickups/editPickup', { ...(commissionedUnit as Pickup) }, { root: true });
    }
  },

  async saveCommissionedUnit({ dispatch }, commissionedUnit: CommissionedUnit) {
    if (commissionedUnit.type === CommissionedUnitType.Freight) {
      await dispatch('freight/saveFreight', commissionedUnit as Freight, { root: true });
    } else if (commissionedUnit.type === CommissionedUnitType.Pickup) {
      await dispatch('pickups/savePickup', commissionedUnit as Pickup, { root: true });
    }
  },

  updateCommissionedUnitDeliveryDate({ commit }, { id, newDate }: { id: string; newDate: string }) {
    commit('updateDeliveryDate', { id, newDate });
    commit('clearTimeSlot', id);
  },

  async setFreightStatus(
    // eslint-disable-next-line no-empty-pattern
    {},
    { id, version, status }: { id: string; version: number; status: string }
  ) {
    await ApiService.setFreightStatus(id, version, { status }).catch(() => {
      /* no-op */
    });
  },

  async setCommissionedUnitStatus(
    { commit, rootState },
    {
      id,
      version,
      commissionedUnit,
      fix
    }: { id: string; version: number; commissionedUnit: CommissionedUnit; fix: boolean }
  ) {
    if (commissionedUnit?.type === CommissionedUnitType.Freight) {
      const status = fix
        ? rootState.app.enums.freightStatus.scheduled
        : rootState.app.enums.freightStatus.new;
      await ApiService.setFreightStatus(id, version, { status }).catch(() => {
        /* no-op */
      });
      commit('setStatus', { id, status });
    } else if (commissionedUnit?.type === CommissionedUnitType.Pickup) {
      const status = fix
        ? rootState.app.enums.pickupStatus.scheduled
        : rootState.app.enums.pickupStatus.new;
      await ApiService.setPickupStatus(id, version, { status }).catch(() => {
        /* no-op */
      });
      commit('setStatus', { id, status });
    }
  },

  async setTimeSlots({ commit, rootGetters, rootState }) {
    const editedObjectType = rootState.freight.editedFreight?.freightId
      ? CommissionedUnitType.Freight
      : CommissionedUnitType.Pickup;
    const editedObject: any =
      editedObjectType === CommissionedUnitType.Freight
        ? rootState.freight.editedFreight
        : rootState.pickups.editedPickup;
    const { data } = await ApiService.getFreeTimeSlots({
      commissionedUnitType: editedObjectType,
      depotShortName: editedObject?.depotShortName,
      commissionedUnitId:
        editedObjectType === CommissionedUnitType.Freight
          ? editedObject?.freightId
          : editedObject?.pickupId,
      date: editedObject?.deliveryDate,
      dockId: editedObject?.timeSlot?.dockId,
      timeSlotWidth:
        editedObject?.timeSlot?.timeSlotWidth !== 1
          ? editedObject?.timeSlot?.timeSlotWidth
          : undefined
    });

    const editedObjectDepot = state.depots?.find(
      (depot: Depot) => depot.shortName === editedObject?.depotShortName
    );

    commit('setTimeSlots', {
      timeSlots: data.timeSlots,
      lengthOfTimeSlot: editedObjectDepot?.lengthOfTimeSlot
        ? editedObjectDepot.lengthOfTimeSlot
        : rootGetters['basicSettings/lengthOfTimeSlot'],
      editedObject
    });
  },

  async setDocks({ commit, rootState }) {
    const editedObjectType = rootState.freight.editedFreight?.freightId
      ? CommissionedUnitType.Freight
      : CommissionedUnitType.Pickup;
    const editedObject: any =
      editedObjectType === CommissionedUnitType.Freight
        ? rootState.freight.editedFreight
        : rootState.pickups.editedPickup;
    const { data } = await ApiService.getFreeDocks({
      commissionedUnitId:
        editedObjectType === CommissionedUnitType.Freight
          ? editedObject?.freightId
          : editedObject?.pickupId,
      commissionedUnitType: editedObjectType,
      depotShortName: editedObject?.depotShortName,
      date: editedObject?.deliveryDate,
      timeSlot: editedObject?.timeSlot?.time,
      timeSlotWidth: editedObject?.timeSlot?.timeSlotWidth
    });
    commit('setDocks', data.docks);
  }
};

const mutations: MutationTree<State> = {
  setDepots(state, depots: Depot[]) {
    state.depots = depots;
  },

  setFreights(state, freights: Freight[]) {
    freights.map((freight) => (freight.type = CommissionedUnitType.Freight));
    state.freights = freights;
  },

  setPickups(state, pickups: Pickup[]) {
    pickups.map((pickup) => (pickup.type = CommissionedUnitType.Pickup));
    state.pickups = pickups;
  },

  setFreightDetails(state, freight: Freight) {
    if (state.freights) {
      const selectedFreight = state.freights.find((fr) => fr.freightId === freight.freightId);
      if (selectedFreight) {
        Object.assign(selectedFreight, freight);
      }
    }
    state.freights = [...(state.freights as Freight[])];
  },

  setPickupDetails(state, pickup: Pickup) {
    if (state.pickups) {
      const selectedPickup = state.pickups.find((pu) => pu.pickupId === pickup.pickupId);
      if (selectedPickup) {
        Object.assign(selectedPickup, pickup);
      }
    }
    state.pickups = [...(state.pickups as Pickup[])];
  },

  setWeekdays(state, { firstDay, overrides }: { firstDay: string; overrides: Calendar[] }) {
    state.weekdays = [];

    for (let i = 0; i < 7; i++) {
      const date = moment(firstDay).add(i, 'days').toISOString();
      let enabled = i < 5;

      const override = overrides.filter((override) => override.date === date);
      if (override.length) {
        enabled = override[0].workday;
      }

      state.weekdays.push({ date, enabled });
    }
  },

  updateDeliveryDate(state, { id, newDate }: { id: string; newDate: string }) {
    const commissionedUnits = [...(state.freights || []), ...(state.pickups || [])];
    const selectedCommissionedUnit = commissionedUnits.find((cu) => cu.id === id);
    if (selectedCommissionedUnit) {
      selectedCommissionedUnit.deliveryDate = newDate;
    }
  },

  setTimeSlots(state, payload) {
    state.timeSlots = [];
    payload.timeSlots.forEach((item: any) => {
      state.timeSlots?.push({
        value: item,
        text: `${item} - ${moment(item, 'HH:mm')
          .add(
            (payload.editedFreight?.timeSlot?.timeSlotWidth
              ? payload.editedFreight?.timeSlot?.timeSlotWidth * payload.lengthOfTimeSlot
              : payload.lengthOfTimeSlot) - 1,
            'minutes'
          )
          .format('HH:mm')}`
      });
    });
  },

  setDocks(state, payload: Dock[]) {
    state.docks = payload;
  },

  clearTimeSlot(state, id: string) {
    const commissionedUnits = [...(state.freights || []), ...(state.pickups || [])];
    const selectedCommissionedUnit = commissionedUnits.find((cu) => cu.id === id);
    if (selectedCommissionedUnit) {
      selectedCommissionedUnit.timeSlot = { time: undefined, dockId: undefined };
    }
  },

  setStatus(state, { id, status }: { id: string; status: string }) {
    const commissionedUnits = [...(state.freights || []), ...(state.pickups || [])];
    const updatedCommissionedUnit = commissionedUnits.find((cu) => cu.id === id);
    if (updatedCommissionedUnit) {
      updatedCommissionedUnit.status = status;
      updatedCommissionedUnit.__v = updatedCommissionedUnit.__v
        ? updatedCommissionedUnit.__v + 1
        : 1;
    }
  },

  updateField
};

const getters: GetterTree<State, RootState> = {
  commissionedUnits: (state) => {
    const freights = state.freights ?? [];
    const pickups = state.pickups ?? [];
    const commissionedUnits = [...freights, ...pickups];

    return commissionedUnits;
  },
  getField
};

export const commissionedUnitOrganizer: Module<State, RootState> = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
