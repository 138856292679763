import { ActionTree, GetterTree, Module, MutationTree } from 'vuex';

import ApiService from '../services/ApiService';
import { RootState } from './';

type State = {
  activeModules: string[];
  activeConfigs: string[];
};

const state: State = {
  activeModules: [],
  activeConfigs: []
};

const actions: ActionTree<State, RootState> = {
  async getActiveModules({ commit }) {
    const { data: modules } = await ApiService.getActiveModules();
    commit('getActiveModules', modules);
  }
};

const mutations: MutationTree<State> = {
  getActiveModules(state, payload) {
    state.activeModules = payload.activeModules;
    state.activeConfigs = payload.activeConfigs;
  }
};

const getters: GetterTree<State, RootState> = {
  // Modules
  allActiveModules: (state) => state.activeModules,
  isModuleActive:
    (state) =>
    (moduleKey: string): boolean => {
      return state.activeModules.includes(moduleKey);
    },
  isStatisticsModuleActive: (state, getters, rootState) =>
    state.activeModules.some((key) => key === rootState.app.constants.MODULE_KEYS.STATISTICS),
  isFreightOfferModuleActive: (state, getters, rootState) =>
    state.activeModules.some((key) => key === rootState.app.constants.MODULE_KEYS.FREIGHT_OFFER),
  isCustomerNotificationModuleActive: (state, getters, rootState) =>
    state.activeModules.some(
      (key) => key === rootState.app.constants.MODULE_KEYS.CUSTOMER_NOTIFICATION
    ),
  isSiteModuleActive: (state, getters, rootState) =>
    state.activeModules.some((key) => key === rootState.app.constants.MODULE_KEYS.SITE),
  isTransporterModuleActive: (state, getters, rootState) =>
    state.activeModules.some((key) => key === rootState.app.constants.MODULE_KEYS.TRANSPORTER),
  isRoutePlannerModuleActive: (state, getters, rootState) =>
    state.activeModules.some((key) => key === rootState.app.constants.MODULE_KEYS.ROUTE_PLANNER),
  isExtendedVisualizationModuleActive: (state, getters, rootState) =>
    state.activeModules.some(
      (key) => key === rootState.app.constants.MODULE_KEYS.EXTENDED_VISUALIZATION
    ),
  isFinalizeFreightModuleActive: (state, getters, rootState) =>
    state.activeModules.some((key) => key === rootState.app.constants.MODULE_KEYS.FINALIZE_FREIGHT),
  isEditWaypointsOnFreightModuleActive: (state, getters, rootState) =>
    state.activeModules.some(
      (key) => key === rootState.app.constants.MODULE_KEYS.FREIGHT_WAYPOINTS_EDIT
    ),
  isOrderClosureModuleActive: (state, getters, rootState) =>
    state.activeModules.some((key) => key === rootState.app.constants.MODULE_KEYS.ORDER_CLOSURE),
  isBillingModuleActive: (state, getters, rootState) =>
    state.activeModules.some((key) => key === rootState.app.constants.MODULE_KEYS.BILLING),
  isTimeSlotModuleActive: (state, getters, rootState) =>
    state.activeModules.some((key) => key === rootState.app.constants.MODULE_KEYS.TIME_SLOT),
  isShippingNotificationModuleActive: (state, getters, rootState) =>
    state.activeModules.some(
      (key) => key === rootState.app.constants.MODULE_KEYS.SHIPPING_NOTIFICATION
    ),
  isPickupModuleActive: (state, getters, rootState) =>
    state.activeModules.some((key) => key === rootState.app.constants.MODULE_KEYS.PICKUP),
  isCapacityEstimateModuleActive: (state, getters, rootState) =>
    state.activeModules.some(
      (key) => key === rootState.app.constants.MODULE_KEYS.CAPACITY_ESTIMATE
    ),
  isOrderExcelImportModuleActive: (state, getters, rootState) =>
    state.activeModules.some(
      (key) => key === rootState.app.constants.MODULE_KEYS.ORDER_EXCEL_IMPORT
    ),
  isProductCatalogModuleActive: (state, getters, rootState) =>
    state.activeModules.some((key) => key === rootState.app.constants.MODULE_KEYS.PRODUCT_CATALOG),

  // Configs
  allActiveConfigs: (state) => state.activeConfigs,
  isDeliveryDateInOffer: (state, getters, rootState) =>
    state.activeConfigs.some(
      (key) => key === rootState.app.constants.FREIGHT_OFFER_MODULE_KEYS.DELIVERY_DATE_IN_OFFER
    ),
  canTransporterSetDeliveryDate: (state, getters, rootState) =>
    state.activeConfigs.some(
      (key) =>
        key === rootState.app.constants.FREIGHT_OFFER_MODULE_KEYS.TRANSPORTER_CAN_SET_DELIVERY_DATE
    ),

  isAddNewOrderManuallyActive: (state, getters, rootState) =>
    state.activeConfigs.some(
      (key) => key === rootState.app.constants.BASE_MODULE_CONFIG_KEYS.ADD_NEW_ORDER_MANUALLY
    ),
  isEditOrderManuallyActive: (state, getters, rootState) =>
    state.activeConfigs.some(
      (key) => key === rootState.app.constants.BASE_MODULE_CONFIG_KEYS.EDIT_ORDER_MANUALLY
    ),
  isEditRecipientPhoneNumberActive: (state, getters, rootState) =>
    state.activeConfigs.some(
      (key) => key === rootState.app.constants.BASE_MODULE_CONFIG_KEYS.EDIT_RECIPIENT_PHONE_NUMBER
    ),
  isEditPreferredTrucksActive: (state, getters, rootState) =>
    state.activeConfigs.some(
      (key) => key === rootState.app.constants.BASE_MODULE_CONFIG_KEYS.EDIT_ORDER_PREFERRED_TRUCKS
    ),
  isEditVehicleDataActive: (state, getters, rootState) =>
    state.activeConfigs.some(
      (key) => key === rootState.app.constants.BASE_MODULE_CONFIG_KEYS.EDIT_VEHICLE_DATA
    ),
  isSaveWaypointRestrictionsActive: (state, getters, rootState) =>
    state.activeConfigs.some(
      (key) => key === rootState.app.constants.BASE_MODULE_CONFIG_KEYS.SAVE_WAYPOINT_RESTRICTIONS
    ),
  canLogisticsManagerModifyBaseConfig: (state, getters, rootState) =>
    state.activeConfigs.some(
      (key) =>
        key ===
        rootState.app.constants.BASE_MODULE_CONFIG_KEYS.LOGISTICS_MANAGER_CAN_MODIFY_BASE_CONFIG
    ),
  canLogisticsManagerSetAutomaticProcessing: (state, getters, rootState) =>
    state.activeConfigs.some(
      (key) =>
        key ===
        rootState.app.constants.BASE_MODULE_CONFIG_KEYS
          .LOGISTICS_MANAGER_CAN_SET_AUTOMATIC_PROCESSING
    ),
  isDeleteOrderActive: (state, getters, rootState) =>
    state.activeConfigs.some(
      (key) => key === rootState.app.constants.BASE_MODULE_CONFIG_KEYS.DELETE_ORDER
    ),

  isOnlyProductionManagerCanPassFreightsActive: (state, getters, rootState) =>
    state.activeConfigs.some(
      (key) =>
        key ===
        rootState.app.constants.SITE_MODULE_CONFIG_KEYS.ONLY_PRODUCTION_MANAGER_CAN_PASS_FREIGHTS
    ),
  canProductionManagerSetReleaseDates: (state, getters, rootState) =>
    state.activeConfigs.some(
      (key) =>
        key ===
        rootState.app.constants.SITE_MODULE_CONFIG_KEYS.PRODUCTION_MANAGER_CAN_SET_RELEASE_DATES
    ),
  canProductionManagerPassFreights: (state, getters, rootState) =>
    state.activeConfigs.some(
      (key) =>
        key === rootState.app.constants.SITE_MODULE_CONFIG_KEYS.PRODUCTION_MANAGER_CAN_PASS_FREIGHTS
    ),
  canProductionManagerSplitFreights: (state, getters, rootState) =>
    state.activeConfigs.some(
      (key) =>
        key ===
        rootState.app.constants.SITE_MODULE_CONFIG_KEYS.PRODUCTION_MANAGER_CAN_SPLIT_FREIGHTS
    ),
  canProductionManagerScheduleFreights: (state, getters, rootState) =>
    state.activeConfigs.some(
      (key) =>
        key ===
        rootState.app.constants.SITE_MODULE_CONFIG_KEYS.PRODUCTION_MANAGER_CAN_SCHEDULE_FREIGHTS
    ),
  canProductionManagerSchedulePickups: (state, getters, rootState) =>
    state.activeConfigs.some(
      (key) =>
        key ===
        rootState.app.constants.SITE_MODULE_CONFIG_KEYS.PRODUCTION_MANAGER_CAN_SCHEDULE_PICKUPS
    ),
  isOnlyProductionManagerCanEditPickupsActive: (state, getters, rootState) =>
    state.activeConfigs.some(
      (key) =>
        key ===
        rootState.app.constants.SITE_MODULE_CONFIG_KEYS.ONLY_PRODUCTION_MANAGER_CAN_EDIT_PICKUPS
    ),
  canProductionUserAccessStatistics: (state, getters, rootState) =>
    state.activeConfigs.some(
      (key) =>
        key ===
        rootState.app.constants.SITE_MODULE_CONFIG_KEYS.PRODUCTION_USER_CAN_ACCESS_STATISTICS
    ),
  canViewCargoSpacesInFreightMenu: (state, getters, rootState) =>
    state.activeConfigs.some(
      (key) =>
        key ===
        rootState.app.constants.EXTENDED_VISUALIZATION_MODULE_CONFIG_KEYS.SHOW_PLAN_IN_FREIGHT_VIEW
    ),
  isTransporterTenderActive: (state, getters, rootState) =>
    state.activeConfigs.some(
      (key) => key === rootState.app.constants.FREIGHT_OFFER_MODULE_KEYS.TRANSPORTER_TENDER
    ),
  isAutomaticFreightAcceptanceActive: (state, getters, rootState) =>
    state.activeConfigs.some(
      (key) =>
        key === rootState.app.constants.FREIGHT_OFFER_MODULE_KEYS.AUTOMATIC_FREIGHT_ACCEPTANCE
    ),
  isTransporterCounterofferActive: (state, getters, rootState) =>
    state.activeConfigs.some(
      (key) =>
        key === rootState.app.constants.FREIGHT_OFFER_MODULE_KEYS.TRANSPORTER_CAN_GIVE_COUNTEROFFER
    ),
  isLogisticsManagerCommentInOfferActive: (state, getters, rootState) =>
    state.activeConfigs.some(
      (key) =>
        key === rootState.app.constants.FREIGHT_OFFER_MODULE_KEYS.LOGISTICS_MANAGER_COMMENT_IN_OFFER
    ),
  isSetPlateAndDriverAtFinalizedStatusActive: (state, getters, rootState) =>
    state.activeConfigs.some(
      (key) =>
        key ===
        rootState.app.constants.FINALIZE_FREIGHT_MODULE_KEYS
          .SET_PLATE_AND_DRIVER_AT_FINALIZED_STATUS
    ),
  isTimeSlotWidthActive: (state, getters, rootState) =>
    state.activeConfigs.some(
      (key) => key === rootState.app.constants.TIME_SLOT_MODULE_CONFIG_KEYS.TIME_SLOT_WIDTH
    ),
  isOrderClosureApiActive: (state, getters, rootState) =>
    state.activeConfigs.some(
      (key) => key === rootState.app.constants.ORDER_CLOSURE_MODULE_CONFIG_KEYS.ORDER_CLOSURE_API
    ),
  isUpdateFromDeliveryToShippedStatusActive: (state, getters, rootState) =>
    state.activeConfigs.some(
      (key) => key === rootState.app.constants.TRANSPORTER_MODULE_CONFIG_KEYS.DELIVERY_STATUS
    ),
  isOnlyTransporterCanSetFreightsToShippedActive: (state, getters, rootState) =>
    state.activeConfigs.some(
      (key) =>
        key ===
        rootState.app.constants.TRANSPORTER_MODULE_CONFIG_KEYS
          .ONLY_TRANSPORTER_CAN_SET_FREIGHTS_TO_SHIPPED
    ),
  isFreightCommentActive: (state, getters, rootState) =>
    state.activeConfigs.some(
      (key) => key === rootState.app.constants.TRANSPORTER_MODULE_CONFIG_KEYS.FREIGHT_COMMENT
    ),

  isProductPriorityActive: (state, getters, rootState) =>
    state.activeConfigs.some(
      (key) => key === rootState.app.constants.BASE_MODULE_CONFIG_KEYS.PRODUCT_PRIORITY
    ),
  isTableStatisticsActive: (state, getters, rootState) =>
    state.activeConfigs.some(
      (key) => key === rootState.app.constants.STATISTICS_MODULE_CONFIG_KEYS.TABLES
    ),
  isChartStatisticsActive: (state, getters, rootState) =>
    state.activeConfigs.some(
      (key) => key === rootState.app.constants.STATISTICS_MODULE_CONFIG_KEYS.CHARTS
    ),
  isMapStatisticsActive: (state, getters, rootState) =>
    state.activeConfigs.some(
      (key) => key === rootState.app.constants.STATISTICS_MODULE_CONFIG_KEYS.MAPS
    ),
  isWienerbergerStatisticsActive: (state, getters, rootState) =>
    state.activeConfigs.some(
      (key) => key === rootState.app.constants.STATISTICS_MODULE_CONFIG_KEYS.WIENERBERGER_TABLES
    ),
  isFreightCostBasedOnCargoWeightActive: (state, getters, rootState) =>
    state.activeConfigs.some(
      (key) =>
        key === rootState.app.constants.BASE_MODULE_CONFIG_KEYS.FREIGHT_COST_BASED_ON_CARGO_WEIGHT
    ),
  isFreightCostCalculatedForDefaultTrucksOnlyActive: (state, getters, rootState) =>
    state.activeConfigs.some(
      (key) =>
        key ===
        rootState.app.constants.BASE_MODULE_CONFIG_KEYS
          .FREIGHT_COST_CALCULATED_FOR_DEFAULT_TRUCKS_ONLY
    ),
  isFreightCostCalculatedForDeliveryToSiteActive: (state, getters, rootState) =>
    state.activeConfigs.some(
      (key) =>
        key ===
        rootState.app.constants.BASE_MODULE_CONFIG_KEYS.FREIGHT_COST_CALCULATED_FOR_DELIVERY_TO_SITE
    ),
  isFreightCostUsePalletFeesActive: (state, getters, rootState) =>
    state.activeConfigs.some(
      (key) => key === rootState.app.constants.BASE_MODULE_CONFIG_KEYS.FREIGHT_COST_USE_PALLET_FEES
    ),
  isFreightCostUseWaypointCostsActive: (state, getters, rootState) =>
    state.activeConfigs.some(
      (key) =>
        key === rootState.app.constants.BASE_MODULE_CONFIG_KEYS.FREIGHT_COST_USE_WAYPOINT_COSTS
    ),
  isOrderGrossTotalWeightActice: (state, getters, rootState) =>
    state.activeConfigs.some(
      (key) => key === rootState.app.constants.BASE_MODULE_CONFIG_KEYS.ORDER_GROSS_TOTAL_WEIGHT
    ),
  isFreightDownloadTransportPlanActive: (state, getters, rootState) =>
    state.activeConfigs.some(
      (key) =>
        key === rootState.app.constants.BASE_MODULE_CONFIG_KEYS.FREIGHT_DOWNLOAD_TRANSPORT_PLAN
    ),
  isFreightDownloadPackingPlanActive: (state, getters, rootState) =>
    state.activeConfigs.some(
      (key) => key === rootState.app.constants.BASE_MODULE_CONFIG_KEYS.FREIGHT_DOWNLOAD_PACKING_PLAN
    ),
  isOrderItemsReleaseDateFollowsOrderReleaseDateActive: (state, getters, rootState) =>
    state.activeConfigs.some(
      (key) =>
        key ===
        rootState.app.constants.BASE_MODULE_CONFIG_KEYS
          .ORDER_ITEMS_RELEASE_DATE_FOLLOWS_ORDER_RELEASE_DATE
    ),
  isHandleWeightRestrictedZonesActive: (state, getters, rootState) =>
    state.activeConfigs.some(
      (key) =>
        key ===
        rootState.app.constants.ROUTE_PLANNER_MODULE_CONFIG_KEYS.HANDLE_WEIGHT_RESTRICTED_ZONES
    ),
  isAutomaticPlanningOnModifyInactiveOrdersActive: (state, getters, rootState) =>
    state.activeConfigs.some(
      (key) =>
        key ===
        rootState.app.constants.BASE_MODULE_CONFIG_KEYS.AUTOMATIC_PLANNING_ON_MODIFY_INACTIVE_ORDERS
    )
};

export const module: Module<State, RootState> = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
